

<template>
    <div>
        <modal height="auto" :name="modal_name" class="final-modal" transition="nice-modal-fade">
            <div class="v-modal-content p-0">
                <div class="m-4">
                    <div class="v-modal-body pt-0 pb-0" style="max-height:430px;overflow-y:auto;overflow-x:auto;">
                        <img src="/static/images/withdrawl_succcess.svg" style="margin:0 auto;display:block;" alt="">
                        <h3 class="text-center fs-18 fw-600 mb-0 p-3 text-danger">Withdrawal Successful</h3>
                    </div>
                    <div class="v-modal-dialog-actions text-center" style="border-radius: 0px 0px 15px 15px;padding:15px;">
                        <a class="btn btn-secondary btn-smm text-white ml-2" style="text-transform: uppercase !important;font-size: 12px !important;border-radius:5px !important;" @click="hideSubscription()">OK</a>
                    </div> 
                </div>     
            </div>
        </modal>
    </div>
</template>

<script>

export default {
    props:['modal_name', 'product_id', 'subscription_text', 'error_text'],
    data(){
        return{
            comment: ''
        }
    },
    methods: {
        hideSubscription(){
            this.$emit('hide-withdraw-modal');
        },
    }
}

</script>

<style scoped>

.v-modal-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}
.v-modal-content{
    padding: 5px;
}
.v-modal-body div form{
    display: flex;
    flex-direction: column;
}

.v-modal-body div input{
    margin-bottom: 10px;
    padding: 10px;
    outline: none;
    border-radius: 5px;
    border: 1px solid black;
    opacity: 0.71
}

</style>
